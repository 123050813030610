import axios, { AxiosError } from "axios";
import {
  ICreateReport,
  IBlockAPIResponse,
  IDataSourcesAPIResponse,
  IReportsAPIResponse,
  IEditBlockPayload,
  ISingleBlockAPIResponse,
  ICreateBlockPayload,
  ICreateBlock,
  ISingleBlockResultAPIResponse,
  ILogin,
  ICreateUser,
  IRegister,
  IAmazonS3Payload,
  ISample,
  IAddFilesToFolder,
  IDataSourcesFoldersAPIResponse,
  UpdateBlockDataSourcesInterface,
} from "types";
import { SetDetails, getDetails } from "utils";
import { toast } from "react-toastify";

export const BASE_URL = process.env["REACT_APP_BACKEND_URL"] as string;

const token = getDetails("token");

export const API = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

API.interceptors.request.use(
  (config) => {
    const token = getDetails("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    console.error(error, "error");

    const status = error.response?.status;
    const loginUrl = error?.config?.url?.includes("/auth/login");
    const userDetailsUrl = error?.config?.url?.includes("/details");

    if (status === 401 && !loginUrl) {
      SetDetails("token", "");
      window.location.href = "/auth";
    } else if (status === 404 && userDetailsUrl) {
      SetDetails("token", "");
      window.location.href = "/auth";
    } else if (status === 403) {
      toast.error("You do not have permission to access this resource.");
    }

    return Promise.reject(error.response?.data || error);
  }
);

export const request = async (options: any) => {
  return API(options);
};

export const createReportApi = async (data: ICreateReport) => {
  const res = await request({
    url: `/reports`,
    data,
    method: "POST",
  });
  return res.data;
};

export const fetchFromS3Api = async (data: IAmazonS3Payload) => {
  const res = await request({
    url: `/data-sources/cloud-storage/s3/list-objects`,
    data: { ...data },
    method: "POST",
  });
  return res.data;
};

export const uploadFromS3Api = async (data: IAmazonS3Payload) => {
  const res = await request({
    url: `/data-sources/cloud-storage/s3/multiple-objects`,
    data: { ...data },
    method: "POST",
  });
  return res.data;
};

export const fetchReportsApi = async (page: string): Promise<IReportsAPIResponse> => {
  const res = await request({
    url: `/reports`,
    method: "GET",
    params: {
      page,
    },
  });
  return res.data;
};

export const fetchDataSourcesApi = async (page?: string, status?: string): Promise<IDataSourcesAPIResponse> => {
  const url = status ? `/data-sources/all?page=${page || 1}&status=${status}` : `/data-sources/all?page=${page || 1}`;
  const res = await request({
    url,
    method: "GET",
  });
  return res.data;
};

export const fetchDataSourcesFolderApi = async (page?: string, status?: string): Promise<IDataSourcesFoldersAPIResponse> => {
  const url = `/data-sources/folders?page=${page || 1}`;
  const res = await request({
    url,
    method: "GET",
  });
  return res.data;
};

export const fetchBlocksApi = async (id: string): Promise<IBlockAPIResponse> => {
  const res = await request({
    url: `/reports/${id}/blocks`,
    method: "GET",
  });
  return res.data;
};

export const editBlockResultApi = async (data: IEditBlockPayload) => {
  const { content, reportId, blockId } = data;

  const res = await request({
    url: `/reports/${reportId}/blocks/${blockId}/results`,
    data: { content },
    method: "PATCH",
  });
  return res.data;
};

export const fetchBlockApi = async (reportId: string, blockId: string): Promise<ISingleBlockAPIResponse> => {
  const res = await request({
    url: `/reports/${reportId}/blocks/${blockId}`,
    method: "GET",
  });
  return res.data;
};

export const fetchBlockSampleApi = async (reportId: string, blockId: string): Promise<any> => {
  const res = await request({
    url: `/reports/${reportId}/blocks/${blockId}/samples`,
    method: "GET",
  });
  return res.data;
};

export const createBlockResultApi = async (data: ICreateBlockPayload) => {
  const { report_id, blocks_data } = data;
  const res = await request({
    url: `/reports/${report_id}/blocks-bulk`,
    data: { blocks_data },
    method: "POST",
  });

  return res.data;
};

export const uploadDataSource = async (formData: FormData) => {
  const res = await request({
    url: `/data-sources/files`,
    data: formData,
    method: "POST",
    timeout: 600000,
    signal: AbortSignal.timeout(600000),
  });
  return res.data;
};

export const uploadDataSample = async (formData: FormData) => {
  const res = await request({
    url: `/samples`,
    data: formData,
    method: "POST",
  });
  return res.data;
};

export const fetchBlockResultApi = async (
  reportId: string,
  blockId: string,
  isRedoing: boolean,
  signal: AbortSignal,
  comment?: string
): Promise<ISingleBlockResultAPIResponse> => {
  const redoingWithComment = comment
    ? `/reports/${reportId}/blocks/${blockId}/results?is_redoing=${isRedoing}&comment=${comment}`
    : `/reports/${reportId}/blocks/${blockId}/results?is_redoing=${isRedoing}`;
  const url = isRedoing ? redoingWithComment : `/reports/${reportId}/blocks/${blockId}/results`;
  const res = await request({
    url,
    method: "GET",
    signal,
  });
  return res.data;
};

export const updateBlockApi = async (data: ICreateBlock) => {
  const { report_id, block_id } = data;

  const res = await request({
    url: `/reports/${report_id}/blocks/${block_id}`,
    data,
    method: "PUT",
  });
  return res.data;
};

export const updateBlockDataSourcesApi = async (data: UpdateBlockDataSourcesInterface) => {
  const { report_id, block_id, data_sources } = data;

  const res = await request({
    url: `/reports/${report_id}/blocks/${block_id}/data-sources`,
    data: { data_sources: data_sources },
    method: "PUT",
  });
  return res.data;
};

export const chatQueryApi = async (data: any) => {
  const { report_id, block_id, session_id, query } = data;

  const res = await request({
    url: `/reports/${report_id}/blocks/${block_id}/queries`,
    data: {
      session_id: session_id || null,
      query,
    },
    method: "POST",
  });

  return res.data;
};

export const fetchBlockImageApi = async (data: any) => {
  const { reportId, blockId } = data;
  const res = await request({
    url: `/reports/${reportId}/blocks/${blockId}/image`,
    method: "GET",
  });

  return res.data;
};

export const deleteReportApi = async (report_ids: string[]) => {
  const res = await request({
    url: `/reports`,
    data: { report_ids },
    method: "DELETE",
  });
  return res.data;
};

export const fetchBlockReferencesApi = async (data: any) => {
  const { reportId, blockId } = data;
  const res = await request({
    url: `/reports/${reportId}/blocks/${blockId}/references`,
    method: "GET",
  });

  return res.data;
};

export const getReportDataSourcesApi = async (reportId: string) => {
  const res = await request({
    url: `/reports/${reportId}/data-sources?status=COMPLETE`,
    method: "GET",
  });
  return res.data;
};

export const getReportSamplesApi = async (reportId: string) => {
  const res = await request({
    url: `/reports/${reportId}/samples`,
    method: "GET",
  });
  return res.data;
};

export const getReportDetails = async (reportId: string) => {
  const res = await request({
    url: `/reports/${reportId}`,
    method: "GET",
  });
  return res.data;
};

export const downloadPdf = async (id: string) => {
  const res = await request({
    url: `/data-sources/${id}/presigned-url`,
    method: "GET",
  });
  return res.data;
};

export const createUserApi = async (data: ICreateUser) => {
  const res = await request({ url: `/auth/register`, data, method: "POST" });
  return res.data;
};

export const loginApi = async (data: ILogin) => {
  const res = await request({ url: `/auth/login`, data, method: "POST" });
  return res.data;
};

export const registerApi = async (data: IRegister) => {
  const res = await request({ url: `/auth/register`, data, method: "POST" });
  return res.data;
};

export const fetchMeApi = async () => {
  const res = await request({ url: `/accounts/details`, method: "GET" });
  return res.data;
};

export const getSignedUrl = async (id: string) => {
  const res = await request({ url: `/data-sources/${id}/presigned-url`, method: "GET", Authorization: `Bearer ${token}` });
  return res.data;
};

export const fetchSamplesApi = async () => {
  const res = await request({ url: `/samples`, method: "GET" });
  return res.data;
};

export const createFolderApi = async (data: any) => {
  const res = await request({ url: `/data-sources/folders`, data, method: "POST" });
  return res.data;
};

export const addFilesToFolderApi = async (data: IAddFilesToFolder) => {
  const { id, data_sources } = data;
  const res = await request({ url: `/data-sources/folders/${id}/data-sources`, data: { data_sources: [...data_sources] }, method: "POST" });
  return res.data;
};

export const getFolderDetailsApi = async (page: number, id: string) => {
  const res = await request({ url: `/data-sources/folders/${id}/data-sources?page=${page}`, method: "GET" });
  return res.data;
};

export const deleteFilesApi = async (sources: string[]) => {
  const res = await request({ url: `/data-sources`, data: { data_sources_ids: [...sources] }, method: "DELETE" });
  return res.data;
};

export const uploadMultipleApi = async (formData: FormData) => {
  const res = await request({
    url: `/data-sources/multiple-files`,
    data: formData,
    method: "POST",
    timeout: 600000,
    signal: AbortSignal.timeout(600000),
  });
  return res.data;
};
