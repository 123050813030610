import Loading from "components/shared/Loading";
import { ReferenceCard } from "./Reference";
import BlockQueryError from "./BlockQueryError";
import { BlockResult, IResultFormat, IResultTypes, ResultStatus } from "types";
import CustomChart from "components/chart/CustomChart";
import Table from "./Table";
import Typography from "components/shared/Typography";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { cleanString, customId } from "utils";
import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChartData } from "chart.js";
import { useEditBlockResult, useFetchBlockResult } from "services";
import { Modal, Tooltip } from "components/shared";
import { RegenerateBlockModal } from "./RegenerateBlockModal";
import classNames from "classnames";
import { BsChatDots } from "react-icons/bs";
import { LuPencilLine, LuRefreshCcw } from "react-icons/lu";
import { edit_note_icon } from "assets/svg";
import { IoCheckmarkSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Chat, IChatMessages } from "./Chat";

interface BlockitemInterface {
  results: BlockResult[];
  computed_result_status?: string;
  resultType?: IResultTypes;
  result_format?: string;
  isEditable: boolean;
  reportId: string;
  blockId: string;
  title: string;
}

export default function BlockItem(res: BlockitemInterface) {
  const navigate = useNavigate();
  const { computed_result_status, result_format, reportId, blockId, results, title } = res;

  //   const { chart_type, content, created, id, result_type, updated } = data;

  const [regenerateComment, setRegenerateComment] = useState("");
  const [isRedoing, setIsRedoing] = useState<boolean>(false);
  const [isUpdateBlockModalOpen, setIsUpdateBlockModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [resultType, setResultType] = useState<IResultTypes>();
  const [chartData, setChartData] = useState<ChartData<"pie">>();

  const [isOpen, setIsChatOpen] = useState(false);
  //   const date = new Date(updated as string).toLocaleDateString();
  //   const time = new Date(updated as string).toLocaleTimeString();

  const customId = "custom-toast-id";
  const [comment, setComment] = useState("");

  const {
    data: blockResult,
    isFetching: isFetchingBlock,
    error: isBlockError,
    isSuccess: isBlockSuccess,
    refetch,
  } = useFetchBlockResult(reportId, blockId, Boolean(results), isRedoing, regenerateComment);

  //   useEffect(() => {
  //     if (!content) {
  //       refetch();
  //     }
  //   }, [content]);

  // const chartFormat = result_type === "json_string";
  // ? blockResult?.data?.result?.block?.result_type
  // : result_type;

  // const responseResultType = content && result_type ? result_type : blockResult?.data?.result?.block?.result_type;

  //  const responseResultType = content && result_type;

  let clean: any = null;
  let tableToRender: string | null = null;

  //   useEffect(() => {
  //     if (result_type === "json_string" && content && content.length === 0 && computed_result_status !== ResultStatus.ERROR) {
  //       setChartData(JSON.parse(content as string));
  //     }
  //   }, [content]);

  //   switch (true) {
  //     case result_format === IResultFormat.TABLE && content && responseResultType === "json_string":
  //       //       &&
  //       //   desctucturedResultStatusFromBlock !== ResultStatus.ERROR:
  //       tableToRender = "html";
  //       clean = cleanString(content as string);
  //       break;
  //     default:
  //       break;
  //   }

  const [chatMessages, setChatMessages] = useState<IChatMessages[]>([]);

  const handleReferesh = () => {
    setIsRedoing(true);
    setTimeout(() => {
      refetch();
    }, 200);
    setIsUpdateBlockModalOpen(false);
  };

  const { editBlock } = useEditBlockResult();
  const handleEditBlock = async (id: string) => {
    setIsEditable(true);
    setIsUpdating(true);
  };

  const updateBlock = async (id: string) => {
    const updatedContent = document.getElementById(String(id))?.innerText;

    const data = {
      content: String(updatedContent),
      reportId,
      blockId: String(id),
    };

    setIsEditable(false);
    editBlock(data, {
      onError: (error, variables, context) => {
        setIsUpdating(false);
        toast.error("Error updating report block", {
          position: "top-center",
          toastId: customId,
          autoClose: 2000,
          theme: "colored",
        });
      },
      onSuccess: (data, variables, context) => {
        setIsUpdating(false);
        toast.success("Report block successfully updated", {
          position: "top-center",
          toastId: customId,
          autoClose: 1500,
          theme: "colored",
        });
      },
    });
  };

  const toggleSidebar = () => {
    setIsChatOpen(!isOpen);
  };

  const primaryResultType = results.length > 0 && results[0]?.result_type;

  return (
    <div className="flex w-full">
      <div className="w-full">
        {isUpdateBlockModalOpen && (
          <Modal
            onClose={() => setIsUpdateBlockModalOpen(false)}
            open={isUpdateBlockModalOpen}
            variant="full"
            children={
              <RegenerateBlockModal
                handleRefresh={handleReferesh}
                onClose={() => setIsUpdateBlockModalOpen(false)}
                setComment={setRegenerateComment}
                isFetchingBlock={isFetchingBlock}
              />
            }
          />
        )}{" "}
        {isOpen && <div className="fixed inset-0 bg-shade-black bg-opacity-50 z-30" onClick={toggleSidebar}></div>}
        <div
          className={`fixed top-0 right-0 h-full  bg-shade-white shadow-lg z-40 transform transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0 w-[40%]" : "translate-x-full "
          }`}
        >
          <Chat
            report_id={reportId}
            block_id={blockId}
            // previous_response={result as string}
            previous_response={"testign chat functions"}
            onClose={toggleSidebar}
            setChatMessages={setChatMessages}
            chatMessages={chatMessages}
            open={isOpen}
          />
        </div>
        <div className="">
          <div className="text-left mb-2">
            <Typography variant="displaysm" weight="semibold" className="text-shade-black">
              {title}{" "}
            </Typography>
          </div>
          <div className="flex justify-between items-center w-full">
            <Typography variant="textsm">{/* Last updated {time} - {date} */}</Typography>

            <div className="flex gap-3 items-center">
              {isEditable ? (
                <Tooltip text="Complete">
                  <div
                    className={classNames("p-[18px] flex gap-2 bg-grey-75 rounded-md items-center justify-center hover:cursor-pointer", {
                      "pointer-events-none bg-grey-200 opacity-30": primaryResultType !== IResultTypes.TEXT,
                      //|| isFetchingBlock || isBlockError,
                      // })},
                    })}
                    onClick={() => updateBlock(blockId)}
                  >
                    <div aria-label="edit complete">
                      {" "}
                      <IoCheckmarkSharp aria-disabled={isUpdating} />{" "}
                    </div>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip text="Update">
                  <div
                    className={classNames("p-[14px] flex gap-2 bg-grey-75 rounded-md items-center justify-center hover:cursor-pointer", {
                      "pointer-events-none bg-grey-200 opacity-30": primaryResultType !== IResultTypes.TEXT || result_format === IResultFormat.TABLE,
                      //|| isFetchingBlock || isBlockError,
                    })}
                    onClick={() => handleEditBlock(blockId)}
                  >
                    <div aria-label="edit">
                      <img src={edit_note_icon} alt="edit" />
                    </div>
                  </div>
                </Tooltip>
              )}

              <Tooltip text="Regenerate">
                <div
                  className={classNames(
                    "p-[18px] flex gap-2 bg-grey-75 hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer"
                    // {
                    //   "pointer-events-none opacity-30": isFetchingBlock,
                    // }
                  )}
                  onClick={() => setIsUpdateBlockModalOpen(true)}
                >
                  <div aria-label="refresh">
                    <LuRefreshCcw />
                  </div>
                </div>
              </Tooltip>
              <Tooltip text="Edit">
                <div
                  className={classNames(
                    "p-[18px] flex gap-2 bg-grey-75  hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer"
                    // {
                    //   "pointer-events-none bg-grey-200 opacity-30": isFetchingBlock,
                    // }
                  )}
                  onClick={() => navigate(`/blocks/${reportId}/edit/${blockId}`)}
                >
                  <div aria-label="update">
                    <LuPencilLine />
                  </div>
                </div>
              </Tooltip>
              <Tooltip text="Chat">
                <div
                  className={classNames(
                    "p-[18px] flex gap-2 bg-grey-75 hover:bg-grey-100 rounded-md items-center justify-center hover:cursor-pointer",
                    {
                      // "pointer-events-none bg-grey-200 opacity-30": !result,
                      //|| isFetchingBlock || isBlockError,
                    }
                  )}
                  onClick={toggleSidebar}
                >
                  <div aria-label="chat">
                    <BsChatDots />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        {/* {computed_result_status === ResultStatus.ERROR && <BlockQueryError reportId={reportId} blockId={blockId} message={result as string} />} */}
        {computed_result_status !== ResultStatus.ERROR && (
          <div>
            {results &&
              results.length > 0 &&
              results.map((result, index) => (
                <div key={index}>
                  <div className="mt-10 w-full text-justify">
                    {result.result_type === IResultTypes.TEXT && result.result_format !== IResultFormat.TABLE && (
                      <>
                        <Text whiteSpace={"pre-line"} borderWidth={isEditable ? "2px" : "0px"} contentEditable={isEditable} id={String(blockId)}>
                          {" "}
                          <MarkdownPreview
                            source={`${result.content}`}
                            wrapperElement={{
                              "data-color-mode": "light",
                            }}
                          />
                        </Text>
                      </>
                    )}

                    {result.result_format === IResultFormat.CHART &&
                      result.result_type === IResultTypes.JSON_STRING &&
                      result.content &&
                      chartData && (
                        <>
                          <div className="border-[1px] border-grey-100 rounded-md p-4 w-full">
                            <CustomChart chartData={result.content} chartType={result.chart_type || "pie_chart"} title={title as string} />
                          </div>
                        </>
                      )}

                    {tableToRender === "html" && (
                      <>
                        <Table data={clean as any} />
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
