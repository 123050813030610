import { IDataSource } from "./data-source.type";

export interface BlockData {
  query: string;
  result_format: string;
  result_style: string;
  title: string;
  extra_information: string;
  table_row_description: string;
  table_columns: string;
  data_sources: string[];
  chart_instruction: string;
  chart_type: string;
  sample_id?: string;
  result_status?: string;
  result_status_detail?: string;
}

export interface IBlock {
  created: string;
  id: number;
  last_result: string;
  last_result_created: string;
  result_format: string;
  result_type: string;
  title: string;
  chart_type: string;
  result_status: string;
  result_status_detail: string;
  results: BlockResult[];
}

export interface BlockResult {
  chart_type: string | null;
  content: string;
  created: string;
  id: string;
  result_type: string;
  result_format: string;
  updated: string;
}

export interface BlockResultsInterface {
  block: IBlock;
  results: BlockResult[];
}

export interface IDBlockAPIResponse {
  data: Partial<BlockData>[];
}

export interface IBlockAPIResponse {
  data: Partial<IBlock>[];
}

export interface ISingleBlockAPIResponse {
  data: BlockDetailsInterface;
  message: string;
}

export interface ISingleBlockResultAPIResponse {
  data: {
    result: BlockInterface;
  };
  message: string;
}

export interface BlockDetailsInterface {
  block: Partial<IBlockResult>;
  data_sources: Partial<IDataSource>[];
}

export interface BlockInterface {
  block: Partial<IBlockResult>;
  results: BlockResult[];
}

export interface ISingleBlockResult {
  data: Data;
  message: string;
}

export interface Data {
  result: Result;
}

export interface Result {
  result: string;
  block: IBlockResult;
}

export interface IBlockResult {
  id: number;
  title: string;
  report: number;
  query: string;
  procedure: any;
  retrieval_plan: any;
  data_source_type: any;
  data_source: number;
  file_datasource_name: any;
  last_result: string;
  result_format: string;
  result_type: string;
  result_style: string;
  extra_information: string;
  table_row_description: string;
  table_columns: string;
  updated: string;
  created: string;
  data_source_id: string;
  chart_type: string;
  chart_instruction: string;
  sample_id?: string;
  result_status: string;
  result_status_detail: string;
}

export interface IEditBlockPayload {
  content: string;
  reportId: string;
  blockId: string;
}

export interface ICreateBlock {
  title?: string;
  report_id: string;
  block_id: string;
  query?: string;
  procedure?: string;
  data_sources?: string[];
  result_format: string;
  result_type: string;
  result_style: string;
  extra_information?: string;
  table_row_description?: string;
  table_columns?: string;
  id?: string;
  chart_type?: string;
  chart_instruction?: string;
  sample_id?: string;
}

export interface UpdateBlockDataSourcesInterface {
  report_id: string;
  block_id: string;
  data_sources: string[];
}

export interface ICreateDataSource {
  data_source_name: string;
  type: string;
  file: File;
}

export interface ICreateBlockPayload {
  report_id: string;
  blocks_data: any;
}

export interface IBlockCardProps {
  block: Partial<IBlock>;
  reportId: string;
}

export enum IResultTypes {
  TEXT = "text",
  IMAGE = "image",
  JSON_STRING = "json_string",
}

export enum IResultFormats {
  PARA = "text",
  IMAGE = "image",
}
